<template>
  <div
    v-if="this.$route.matched.length > 2"
    class="animated fadeIn"
  >
    <router-view />
  </div>
  <div
    v-else
    class="animated fadeIn"
  >
    <b-card no-body>
      <b-card-header v-b-toggle.filterBody>
        Фильтр
      </b-card-header>
      <b-collapse
        id="filterBody"
        visible
        class="card-body"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group label="Название тендера">
                <b-form-input
                  v-model="filter.name"
                  type="text"
                  autocomplete="false"
                  @input="updateFilter('name')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Автор">
                <b-form-input
                  v-model="filter.user"
                  type="text"
                  autocomplete="false"
                  @input="updateFilter('user')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Маршрут"
              >
                <el-select-clearable
                  v-model="filter.route_ways"
                  class="form-control"
                  name="route-code"
                  placeholder="Начните вводить код маршрута"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="customersTendersRouteWaysList"
                  :loading="loading"
                  :disabled="filter.category === 'cancelled'"
                  @input="debounceFilter()"
                  @clear="customersTendersRouteWaysList"
                >
                  <el-option
                    v-for="item in routeIds"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Статус"
              >
                <el-select-clearable
                  v-model="filter.status"
                  class="form-control"
                  name="status"
                  placeholder=""
                  :multiple="true"
                  clearable
                  :loading="loading"
                  :disabled="filter.category === 'drafts' || filter.category === 'graduated' || filter.category === 'cancelled'"
                  @input="updateFilter('status')"
                >
                  <el-option
                    v-for="item in statuses"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Группа ответственных"
              >
                <el-select-clearable
                  v-model="filter.stl_id"
                  class="form-control"
                  name="manager"
                  placeholder="Начните вводить название"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="customersRoutesManagersList"
                  :loading="loading"
                  @input="updateFilter('stl_id')"
                  @clear="customersRoutesManagersList"
                >
                  <el-option
                    v-for="item in managers"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Общее кол-во рейсов от">
                <b-form-input
                  v-model="filter.route_count_from"
                  type="number"
                  autocomplete="false"
                  @change="updateFilter('route_count_from')"
                  @wheel="$event.target.blur()"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Общее кол-во рейсов до">
                <b-form-input
                  v-model="filter.route_count_to"
                  type="number"
                  autocomplete="false"
                  @change="updateFilter('route_count_to')"
                  @wheel="$event.target.blur()"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Начальная стоимость от">
                <b-form-input
                  v-model="filter.summ_from"
                  type="number"
                  autocomplete="false"
                  @change="updateFilter('summ_from')"
                  @wheel="$event.target.blur()"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Начальная стоимость до">
                <b-form-input
                  v-model="filter.summ_to"
                  type="number"
                  autocomplete="false"
                  @change="updateFilter('summ_to')"
                  @wheel="$event.target.blur()"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Дата старта тендера от">
                <el-date-picker-input
                  v-model="filter.start_date_from"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  :disabled="filter.category === 'drafts' || filter.category === 'cancelled'"
                  @change="updateDateFilter('start_date_from')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Дата старта тендера до">
                <el-date-picker-input
                  v-model="filter.start_date_to"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  :disabled="filter.category === 'drafts' || filter.category === 'cancelled'"
                  @change="updateDateFilter('start_date_to')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Дата окончания тендера от">
                <el-date-picker-input
                  v-model="filter.end_date_from"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  :disabled="filter.category === 'drafts' || filter.category === 'cancelled'"
                  @change="updateDateFilter('end_date_from')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Дата окончания тендера до">
                <el-date-picker-input
                  v-model="filter.end_date_to"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  :disabled="filter.category === 'drafts' || filter.category === 'cancelled'"
                  @change="updateDateFilter('end_date_to')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Кол-во предложений от">
                <b-form-input
                  v-model="filter.offers_count_from"
                  type="number"
                  autocomplete="false"
                  :disabled="filter.category === 'drafts' || filter.category === 'cancelled'"
                  @change="updateFilter('offers_count_from')"
                  @wheel="$event.target.blur()"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Кол-во предложений до">
                <b-form-input
                  v-model="filter.offers_count_to"
                  type="number"
                  autocomplete="false"
                  :disabled="filter.category === 'drafts' || filter.category === 'cancelled'"
                  @change="updateFilter('offers_count_to')"
                  @wheel="$event.target.blur()"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                class="form-group-el-select"
                label="Точка отправления"
              >
                <el-select
                  v-model="filter.first_point"
                  class="form-control"
                  name="first_point"
                  placeholder="Введите точку отправления"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :loading="loading"
                  :remote-method="firstDepartmentsList"
                  @input="updateFilter('first_point')"
                  @clear="firstDepartmentsList"
                >
                  <el-option
                    v-for="item in firstDepartments"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                class="form-group-el-select"
                label="Конечная точка"
              >
                <el-select
                  v-model="filter.last_point"
                  class="form-control"
                  name="last_point"
                  placeholder="Введите конечную точку"
                  clearable
                  reserve-keyword
                  :filterable="true"
                  :loading="loading"
                  :remote="true"
                  :remote-method="lastDepartmentsList"
                  @input="updateFilter('last_point')"
                  @clear="lastDepartmentsList"
                >
                  <el-option
                    v-for="item in lastDepartments"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-collapse>
    </b-card>
    <ul class="nav nav-tabs tender-filter-tabs">
      <li
        v-for="(category, index) in categories"
        :key="'category-' + index"
        class="nav-item tender-filter-tabs__item"
      >
        <input
          :id="'category-' + index"
          v-model="filter.category"
          class="tender-filter-tabs__input"
          type="radio"
          name="category"
          :value="category.value"
          @change="updateFilter('category')"
        >
        <label
          :for="'category-' + index"
          class="nav-link tender-filter-tabs__display"
        >{{ category.text }}</label>
      </li>
    </ul>
    <b-card no-body>
      <b-card-header>
        Тендеры
        <div class="card-header-actions">
          <small class="text-muted">Дата последнего обновления {{ lastRefreshTime }} </small>
          <b-button
            :disabled="loading"
            variant="primary"
            size="sm"
            class="ml-1"
            @click="$refs.table.refresh()"
          >
            <i
              :class="{'fa-spin' : loading}"
              class="fa fa-refresh"
            />&nbsp;Обновить
          </b-button>
          <b-button
            v-if="$store.getters.isAdminOrManager"
            variant="primary"
            size="sm"
            to="/tenders/customer/add"
            class="ml-2"
          >
            <i class="fa fa-plus" />&nbsp;Добавить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{'loading' : loading}">
        <flight-legend :transportation-types="types" />
        <v-server-table
          v-if="filter.ready"
          id="dataTable"
          ref="table"
          :columns="columns"
          :options="options"
          :theme="theme"
          @loaded="loading = false"
        >
          <span
            slot="userId"
            slot-scope="props"
          >
            <span
              class="text-break"
            >{{ props.row.userId }}</span>
          </span>
          <span
            slot="routeWays"
            slot-scope="props"
          >
            <span
              v-for="routeTemplate in props.row.routeTemplates"
              :key="`tender-${props.row.id}-route-way-${routeTemplate.routeWay.id}`"
            ><span>{{ routeTemplate.routeWay.code}}</span><span v-if="routeTemplate.routeType">: {{ displayRouteType(routeTemplate.routeType) }}</span><br></span>
          </span>
          <span
            slot="transportationName"
            slot-scope="props"
          >
            <span
              v-for="routeTemplate in props.row.routeTemplates"
              :key="routeTemplate.id"
            >
              <span
                v-if="routeTemplate.transportation"
                :style="`backgroundColor: ${routeTemplate.transportation.color};`"
                class="flight-card"
              >
                <span :style="routeTemplate.transportation.color == null ? 'color: black;' : 'color: white;'">{{ routeTemplate.transportation.name }}</span>
              </span><br>
            </span>
          </span>
          <span
            slot="transportationType"
            slot-scope="props"
          >
            <span
              v-for="routeTemplate in props.row.routeTemplates"
              :key="routeTemplate.routeWay.id"
            >{{ routeTemplate.routeWay.transportationType.name }}<br></span>
          </span>
          <span
            slot="stl"
            slot-scope="props"
          >
            <span v-if="props.row.tenderOwners.length > 0">
              <span 
              v-for="tenderOwner in props.row.tenderOwners"
              :key="'tender-owner-' + tenderOwner.id"
              >
                <span
                  v-if="tenderOwner"
                  class="nowrap"
                >{{ tenderOwner.name }}</span>
                <br>
              </span>
            </span>
          </span>
          <span
            slot="summ"
            slot-scope="props"
          >{{ numberRUFormat(props.row.summ) }} руб. {{ alternativeAmountValue(props.row.withNDS, props.row.summ) }}</span>
          <span
            slot="activeBid"
            slot-scope="props"
          >
            <span
              v-if="props.row.activeBid"
            >
              ({{ props.row.activeBid.contractor.name }}) — {{ numberRUFormat(props.row.activeBid.sum) }} руб. {{ alternativeAmountValue(props.row.withNDS, props.row.activeBid.sum) }}
            </span>
          </span>
          <span
            slot="winnerProposal"
            slot-scope="props"
          >
            <span v-if="props.row.winnerProposal">
              ({{ props.row.winnerProposal.contractor.name }}) — {{ numberRUFormat(props.row.winnerProposal.sum) }} руб. {{ alternativeAmountValue(props.row.withNDS, props.row.winnerProposal.sum) }}
            </span>
            <span v-else>Н/Д</span>
          </span>
          <span
            slot="startDate"
            slot-scope="props"
          >{{ formatDatetime(props.row.startDate) }}</span>
          <span
            slot="stopDate"
            slot-scope="props"
          >
            <span v-if="props.row.factEndDate">{{ formatDatetime(props.row.factEndDate) }}</span>
            <span v-else>{{ formatDatetime(props.row.stopDate) }}</span>
          </span>
          <span
            slot="status"
            slot-scope="props"
          >
            <span
              v-if="props.row.status === 1"
              class="text-primary text-break"
            >Ожидает начала</span>
            <span
              v-else-if="props.row.status === 2"
              class="text-success text-break"
            >Прием предложений</span>
            <span
              v-else-if="props.row.status === 3"
              class="text-warning text-break"
            >Нет победителя</span>
            <span
              v-else-if="props.row.status === 4"
              class="text-success text-break"
            >Есть победитель</span>
            <span
              v-else-if="props.row.status === 6"
              class="text-success text-break"
            >Утвержден</span>
            <span
              v-else-if="props.row.status === 7"
              class="text-warning text-break"
            >Ожидает генерации рейсов</span>
          </span>
          <span
            slot="dimensions"
            slot-scope="props"
          >
            <span
              v-for="routeTemplate in props.row.routeTemplates"
              :key="routeTemplate.id"
              class="nowrap"
            >
              <span>
                <dimensions-view
                  v-if="props.row.status !== 6"
                  :value="dimensionsList(routeTemplate.routeWay.id, routeTemplate.dimension, routeTemplate.optionalDimensions)"
                  :price="routeTemplate.routeSumm"
                />
                <dimensions-view
                  v-else
                  :value="dimensionsList(routeTemplate.routeWay.id, routeTemplate.dimension, routeTemplate.optionalDimensions)"
                  :price="props.row.winnerProposal.sum"
                />
              </span>
            </span>
          </span>
          <span
            slot="actions"
            slot-scope="props"
            class="d-flex flex-wrap flex-column align-items-center justify-content-center"
          >
            <a
              v-if="props.row.status === 0"
              v-b-tooltip.hover.bottom="'Запустить тендер'"
              class="fa fa-mail-forward fa-lg text-success table-action-button mt-1 mb-2"
              @click="openTenderStartModal(props.row)"
            />
            <a
              v-if="props.row.status === 1"
              v-b-tooltip.hover.bottom="'Изменить период проведения'"
              class="fa fa-history fa-lg text-warning table-action-button mb-2"
              @click="openTenderStartModal(props.row)"
            />
            <a
              v-if="props.row.status === 3"
              v-b-tooltip.hover.bottom="'Перезапустить тендер'"
              class="fa fa-repeat fa-lg text-warning table-action-button mb-2"
              @click="openTenderStartModal(props.row)"
            />
            <a
              v-if="props.row.status === 0"
              v-b-tooltip.hover.bottom="'Редактировать'"
              class="fa fa-pencil fa-lg text-primary table-action-button mb-2"
              :href="'/tenders/customer/tender/id' + props.row.id"
            />
            <a
              v-if="props.row.status !== 0 && props.row.status !== 5 && props.row.status !== 6 && props.row.status !== 7"
              v-b-tooltip.hover.bottom="'Вернуть в черновик'"
              class="fa fa-reply fa-lg text-primary table-action-button mb-2"
              @click="onDraft(props.row.id)"
            />
            <a
              v-b-tooltip.hover.bottom="'Просмотреть'"
              class="fa fa-eye fa-lg text-secondary table-action-button mb-2"
              :href="'/customers/id' + $store.state.user.ownerId + '/tender/id' + props.row.id"
            />
            <a 
            v-if="props.row.commentInfo"
            v-b-tooltip.hover.bottom="'Прочитать комментарий'"
            class="fa fa-exclamation fa-lg text-danger table-action-button mb-2"
            @click="showCommentModal(props.row)"
            />
            <a
              v-if="props.row.status !== 5 && props.row.status !== 6 && props.row.status !== 7"
              v-b-tooltip.hover.bottom="'Удалить тендер'"
              class="fa fa-close fa-lg text-danger table-action-button"
              @click="confirmDeleteTender(props.row.id)"
            />
          </span>
        </v-server-table>
      </b-card-body>
    </b-card>

  <b-modal
    v-model="showComment"
    :title="'Комментарии к тендеру ' + tenderName"
    class="modal-warning"
    cancel-title="Назад"
    ok-only
    >
    <div 
    v-for="(tenderTemplate, index) in this.tenderInfo"
    :key = "'tenderTemplate ' + index"
    > 
    <span class="font-weight-bold">{{tenderTemplate.routeWay.code}}:</span>
    {{tenderTemplate.comment}}
    </div>
    </b-modal>

    <b-modal
      v-model="startTenderModal.show"
      :title="'Запуск тендера ' + tendersObject.name"
      class="modal-warning"
      cancel-title="Назад"
      ok-variant="success"
      :ok-disabled="startTenderDisabled"
      ok-title="Запустить тендер"
      @ok="onStart()"
    >
      <p>Выберите тип запуска тендера и назначьте время</p>
      <b-form-group
        class="form-group-el-select"
        label="Способ запуска"
      >
        <el-select
          v-model="startTenderModal.method.value"
          class="form-control"
          name="route-way"
          :loading="loading"
        >
          <el-option
            v-for="item in startTenderModal.method.options"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </b-form-group>
      <b-form-group
        v-if="startTenderModal.method.value"
        label="Старт тендера"
        label-for="start-tender-modal-start-date"
      >
        <el-date-picker-input
          id="start-tender-modal-start-date"
          v-model="startTenderModal.startDate"
          type="datetime"
          placeholder=""
          format="dd.MM.yyyy HH:mm"
          :picker-options="pickerOptions"
        />
      </b-form-group>
      <b-form-group
        label="Окончание приема ставок"
        label-for="start-tender-modal-end-date"
      >
        <el-date-picker-input
          id="start-tender-modal-end-date"
          v-model="startTenderModal.stopDate"
          type="datetime"
          placeholder=""
          format="dd.MM.yyyy HH:mm"
          :picker-options="pickerOptions"
        />
      </b-form-group>
      <b-form-group>
        <b-form-checkbox
          id="enableOffers"
          v-model="startTenderModal.enableOffers"
          name="enable-offers"
        >
          Возможность подачи встречных предложений
        </b-form-checkbox>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import DimensionsView from '../../components/DimensionsView.vue'
import ElDatePickerInput from '../../components/ElDatePickerInput.vue';
import ElSelectClearable from '../../components/ElSelectClearable.vue';
import Vue from 'vue';
import {ServerTable, Event} from 'vue-tables-2';
import notifications from '../../components/mixins/notifications';
import {
  customersTendersRouteWaysList,
  customersTendersList,
  customersTenderClearRun,
  customersTenderChangeDates,
  customersTenderDraft,
  customersTenderCancel,
  departmentsList,
  routeOwnersList,
  routesTransportationTypesList,
} from '../../services/api';
import {valueToValueWithVAT, valueToValueWithoutVAT, numberToRUFormat} from '@/components/mixins/helpers';
import moment from 'moment';
import tz from 'moment-timezone';
import {arrayStringToNumber} from '@/components/mixins/helpers';
import {queryToUrl, URLToQuery} from '@/services/http';
import FlightLegend from '../../components/FlightLegend.vue';

Vue.use(ServerTable);

export default {
  name: 'CustomerTenders',
  components: {
    ServerTable,
    Event,
    ElSelectClearable,
    ElDatePickerInput,
    FlightLegend, DimensionsView,
  },
  mixins: [notifications],
  data: function() {
    return {
      lastRefreshTime: null,
      tendersObject: {},
      types: null,
      categories: [
        {
          value: 'drafts',
          text: 'Черновики',
        },
        {
          value: 'active',
          text: 'Активные',
        },
        {
          value: 'completed',
          text: 'Завершенные',
        },
        {
          value: 'graduated',
          text: 'Утвержденные',
        },
        {
          value: 'cancelled',
          text: 'Отмененные',
        },
      ],
      routeIds: [],
      tenderId: null,
      tenderInfo: [],
      tenderName: null,
      showComment: false,
      comment: null,
      contractors: [],
      statuses: [],
      managers: [],
      firstDepartments: [],
      lastDepartments: [],
      filter: {
        category: 'drafts',
        name: null,
        user: null,
        route_ways: [],
        route_count_from: null,
        route_count_to: null,
        summ_from: null,
        summ_to: null,
        start_date_from: null,
        start_date_to: null,
        end_date_from: null,
        end_date_to: null,
        offers_count_from: null,
        offers_count_to: null,
        contractor: [],
        status: [],
        stl_id: [],
        first_point: [],
        last_point: [],
        page: 1,
        limit: 100,
        ready: false,
      },
      loading: false,
      columns: [
        'name',
        'userId',
        'routeWays',
        'routeCount',
        'transportationName',
        'transportationType',
        'stl',
        'summ',
        'dimensions',
        'actions',
      ],
      options: {
        customFilters: [
          'category',
          'name',
          'user',
          'first_point',
          'last_point',
          'route_ways',
          'route_count_from',
          'route_count_to',
          'summ_from',
          'summ_to',
          'start_date_from',
          'start_date_to',
          'end_date_from',
          'end_date_to',
          'offers_count_from',
          'offers_count_to',
          'status',
          'stl_id',
        ],
        requestFunction: (data) => {
          this.loading = true;
          this.filter.page = data.page;
          this.filter.limit = data.limit;
          this.customersTendersRouteWaysList();
          this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
          return customersTendersList(this.$store.state.user.ownerId, Object.assign(data, this.updateRouteParams()));
        },
        responseAdapter({data}) {
          data.items.forEach((item) => {
            if (item.routeTemplates) {
              for (let routeTemplate in item.routeTemplates) {
                  if (item.routeTemplates[routeTemplate].comment && 
                  (item.routeTemplates[routeTemplate].comment.comment !== undefined ||
                  item.routeTemplates[routeTemplate].comment.comment !== '' )) {
                    item.commentInfo = true;
                  }
              };
            }
            if (item.tenderContractorProposals.length > 0) {
              item.tenderContractorProposals = item.tenderContractorProposals.filter((x) => x.round === item.round);
              item.tenderContractorProposals.forEach((proposal) => {
                if (proposal.winner) {
                  item.winnerProposal = proposal;
                } else if (proposal.active) {
                  item.activeBid = proposal;
                }
              });
            }
            item.tenderContractorProposals = item.tenderContractorProposals.length;
          });
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          'name': 'Название',
          'userId': 'Автор',
          'routeWays': 'Маршруты и виды маршрутов',
          'routeCount': 'Общее кол-во потенциальных рейсов',
          'transportationName': 'Типы перевозок',
          'transportationType': 'Виды перевозок',
          'stl': 'Группа ответственных',
          'summ': 'Начальная стоимость',
          'activeBid': 'Перевозчик и актуальная ставка',
          'winnerProposal': 'Победитель и финальное предложение',
          'tenderContractorProposals': 'Кол-во ставок/пред-ложений',
          'startDate': 'Старт тендера',
          'stopDate': 'Окончание тендера',
          'dimensions': 'Габариты',
          'status': 'Статус',
          'actions': '',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        perPage: 100,
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      startTenderModal: {
        show: false,
        method: {
          options: [
            {value: 0, text: 'Запустить сейчас'},
            {value: 1, text: 'Запустить как отложенный'},
          ],
          value: 0,
        },
        startDate: '',
        stopDate: '',
        enableOffers: true,
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  computed: {
    dimensionsList(routeId, dimension, optionalDimensions) {
      return (routeId, dimension, optionalDimensions) => {
        return {
          id: routeId,
          ...(dimension && {dimension: dimension}),
          ...(optionalDimensions?.length > 0 && {optionalDimensions: optionalDimensions}),
        };
      };
    },
    startTenderDisabled() {
      if (this.startTenderModal.method.value) {
        return !(this.startTenderModal.startDate && this.startTenderModal.stopDate);
      } else {
        return !this.startTenderModal.stopDate;
      }
    },
    formStrStart() {
      const calculationFormData = {};
      if (this.startTenderModal.method.value && this.startTenderModal.startDate) {
        calculationFormData.start_date = moment(this.startTenderModal.startDate).format();
      }
      if (this.startTenderModal.stopDate) {
        calculationFormData.stop_date = moment(this.startTenderModal.stopDate).format();
      }
      calculationFormData.enable_offers = this.startTenderModal.enableOffers;
      return calculationFormData;
    },
    displayRouteType() {
    return function(routeType) {
      if (routeType === 'oneWay') {
        return 'в один конец';
      } else if (routeType === 'circular') {
        return 'круговой';
      }
    };
  }
  },
  mounted() {
    this.routesTransportationTypesList();
    this.customersRoutesManagersList();
    this.firstDepartmentsList();
    this.lastDepartmentsList();
    const queries = this.$router.currentRoute.query;
    /**
       * Если в URL есть параметр 'limit'
       * то перекидываем таблицу на соответствующее
       * параметру значение
       * */
    if (queries.limit) {
      this.$refs.table.setLimit(queries.limit);
    }
    /**
       * Если в URL есть параметр 'page'
       * то перекидываем таблицу на соответствующее
       * параметру значение
       * */
    if (queries.page) {
      this.$refs.table.setPage(queries.page);
    }
    /**
       * Записываем параметр при
       * изменении страницы таблицы
       * и записываем в URL
       * */
    Event.$on('vue-tables.pagination', (data) => {
      this.updateRouteParams();
    });
    Event.$on('vue-tables.limit', (data) => {
      this.filter.page = 1;
      this.filter.limit = data;
      this.updateRouteParams();
    });
  },
  created() {
    this.initRouteParams();
  },
  methods: {
    alternativeAmountValue(vat, value) {
      if (value) {
        if (!vat) {
          return '(' + this.numberRUFormat(valueToValueWithVAT(value)) + ' руб. С НДС)';
        } else {
          return '(' + this.numberRUFormat(valueToValueWithoutVAT(value)) + ' руб. Без НДС)';
        }
      } else {
        return '';
      }
    },
    numberRUFormat(value) {
      return numberToRUFormat(value);
    },
    showCommentModal(tender) {
      this.showComment = true;
      this.tenderId = tender.id;
      this.tenderName = tender.name;
      this.tenderInfo = [];
      for (let routeTemplate in tender.routeTemplates) {
        this.tenderInfo.push(tender.routeTemplates[routeTemplate]);
      };
    },
    openTenderStartModal(object) {
      this.startTenderModal.show = true;
      this.tendersObject = object;
    },
    getFormData: function(object) {
      const str = [];
      for (const p in object) {
        if (object.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(object[p]));
        }
      }
      return str.join('&');
    },
    async customersTendersRouteWaysList(name) {
      this.loading = true;
      const params = {limit: 100};
      if (this.filter.category === 'drafts') {
        [0].map((item, index) => {
          params['status[' + index + ']'] = item;
        });
      } else if (this.filter.category === 'active') {
        [1, 2].map((item, index) => {
          params['status[' + index + ']'] = item;
        });
      } else if (this.filter.category === 'completed') {
        [3, 4].map((item, index) => {
          params['status[' + index + ']'] = item;
        });
      } else if (this.filter.category === 'graduated') {
        [6, 7].map((item, index) => {
          params['status[' + index + ']'] = item;
        });
      } else if (this.filter.category === 'cancelled') {
        [5].map((item, index) => {
          params['status[' + index + ']'] = item;
        });
      }
      if (name) {
        params.name = name;
      }
      const response = await customersTendersRouteWaysList(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        this.routeIds = response.data.items.map((item) => {
          return {value: item.id, text: item.code};
        });
      }
      if (this.filter.route_ways.length > 0) {
        this.customersSelectedRouteWaysList();
      }
      this.loading = false;
    },
    async customersSelectedRouteWaysList() {
      this.loading = true;
      const selectedRouteWays = {};
      this.filter.route_ways.map((item, index) => {
        selectedRouteWays['ids[' + index + ']'] = item;
      });
      const response = await customersTendersRouteWaysList(this.$store.state.user.ownerId, selectedRouteWays);
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.routeIds = this.routeIds.filter((x) => x.value !== item.id);
          this.routeIds.unshift({value: item.id, text: item.code});
        });
      }
      this.loading = false;
    },
    async firstDepartmentsList(name) {
      this.loading = true;
      const params = {limit: 100};
      if (name) {
        params.name = name;
      }
      const response = await departmentsList(params);
      if (response && response.status === 200) {
        this.firstDepartments = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.code,
          };
        });
      }
      this.loading = false;
    },
    async lastDepartmentsList(name) {
      this.loading = true;
      const params = {limit: 100};
      if (name) {
        params.name = name;
      }
      const response = await departmentsList(params);
      if (response && response.status === 200) {
        this.lastDepartments = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.code,
          };
        });
      }
      this.loading = false;
    },
    async routesTransportationTypesList() {
      this.loading = true;
      const response = await routesTransportationTypesList({limit: 100});
      if (response && response.status === 200) {
        this.types = response.data.items.map((item) => {
          return {value: item.id, text: item.name, color: item.color, description: item.description};
        });
        this.types.unshift({value: null, text: 'Все'});
      }
      this.loading = false;
    },
    async onDraft(tendersId) {
      this.loading = true;
      const response = await customersTenderDraft(this.$store.state.user.ownerId, tendersId);
      if (response && response.status === 200) {
        this.showSuccess('Тендер переведен в черновик');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    confirmDeleteTender(tendersId) {
      this.$bvModal
        .msgBoxConfirm('Вы уверены что хотите удалить тендер?', {
          okTitle: 'Да',
          cancelTitle: 'Назад',
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteTender(tendersId);
          }
        });
    },
    async deleteTender(tendersId) {
      this.loading = true;
      const response = await customersTenderCancel(this.$store.state.user.ownerId, tendersId);
      if (response && response.status === 200) {
        this.showSuccess('Тендер удален');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    formatDatetime(date) {
      return date ? moment(date).format('DD.MM.YYYY HH:mm') : '';
    },
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== '' ? this.filter[field] : null;
        if (field === 'category') {
          this.filter.status = [];
          if (this.filter.category === 'drafts') {
            this.statuses = [
              {
                value: 0,
                text: 'Черновик',
              },
            ];
            this.columns = [
              'name',
              'userId',
              'routeWays',
              'routeCount',
              'transportationName',
              'transportationType',
              'stl',
              'summ',
              'dimensions',
              'actions',
            ];
          } else if (this.filter.category === 'active') {
            this.statuses = [
              {
                value: 1,
                text: 'Ожидает начала',
              },
              {
                value: 2,
                text: 'Прием предложений',
              },
            ];
            this.columns = [
              'name',
              'userId',
              'routeWays',
              'routeCount',
              'transportationName',
              'transportationType',
              'stl',
              'summ',
              'activeBid',
              'tenderContractorProposals',
              'startDate',
              'stopDate',
              'dimensions',
              'status',
              'actions',
            ];
          } else if (this.filter.category === 'completed') {
            this.statuses = [
              {
                value: 3,
                text: 'Нет победителя',
              },
              {
                value: 4,
                text: 'Есть победитель',
              },
            ];
            this.columns = [
              'name',
              'userId',
              'routeWays',
              'routeCount',
              'transportationName',
              'transportationType',
              'stl',
              'summ',
              'winnerProposal',
              'tenderContractorProposals',
              'startDate',
              'stopDate',
              'dimensions',
              'status',
              'actions',
            ];
          } else if (this.filter.category === 'graduated') {
            this.statuses = [
              {
                value: 6,
                text: 'Утвержден',
              },
              {
                value: 7,
                text: 'Ожидание генерации рейсов',
              },
            ];
            this.columns = [
              'name',
              'userId',
              'routeWays',
              'routeCount',
              'transportationName',
              'transportationType',
              'stl',
              'summ',
              'winnerProposal',
              'tenderContractorProposals',
              'startDate',
              'stopDate',
              'dimensions',
              'status',
              'actions',
            ];
          } else if (this.filter.category === 'cancelled') {
            this.statuses = [
              {
                value: 5,
                text: 'Отменен',
              },
            ];
            this.columns = [
              'name',
              'userId',
              'routeWays',
              'routeCount',
              'transportationName',
              'transportationType',
              'summ',
              'dimensions',
              'actions',
            ];
          }
        }
        this.updateRouteParams();
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    debounceFilter: function() {
      if (this.timeout) {
        clearTimeout(this.timeout); 
      }
      this.timeout = setTimeout(() => {
        this.updateFilter('route_ways');
      }, 800); 
    },
    updateDateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] ? moment(this.filter[field]).format('YYYY-MM-DDTHH:mm') : null;
        this.updateRouteParams();
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    updateRouteParams() {
      const res = {};
      if (this.filter.name) {
        res.name = this.filter.name;
      }
      if (this.filter.user) {
        res.user = this.filter.user;
      }
      if (this.filter.route_ways.length) {
        res.route_ways = this.filter.route_ways;
      }
      if (this.filter.stl_id.length) {
        res.stl_id = this.filter.stl_id;
      }
      if (this.filter.route_count_from) {
        res.route_count_from = this.filter.route_count_from;
      }
      if (this.filter.route_count_to) {
        res.route_count_to = this.filter.route_count_to;
      }
      if (this.filter.summ_from) {
        res.summ_from = this.filter.summ_from;
      }
      if (this.filter.summ_to) {
        res.summ_to = this.filter.summ_to;
      }
      if (this.filter.start_date_from) {
        res.start_date_from = moment(this.filter.start_date_from).utc().format('YYYY-MM-DDTHH:mm');
      }
      if (this.filter.start_date_to) {
        res.start_date_to = moment(this.filter.start_date_to).utc().format('YYYY-MM-DDTHH:mm');
      }
      if (this.filter.end_date_from) {
        res.end_date_from = moment(this.filter.end_date_from).utc().format('YYYY-MM-DDTHH:mm');
      }
      if (this.filter.end_date_to) {
        res.end_date_to = moment(this.filter.end_date_to).utc().format('YYYY-MM-DDTHH:mm');
      }
      if (this.filter.offers_count_from) {
        res.offers_count_from = this.filter.offers_count_from;
      }
      if (this.filter.offers_count_to) {
        res.offers_count_to = this.filter.offers_count_to;
      }
      if (this.filter.status && this.filter.status.length) {
        res.status = this.filter.status;
      } else {
        if (this.filter.category === 'drafts') {
          res.status = [0];
        } else if (this.filter.category === 'active') {
          res.status = [1, 2];
        } else if (this.filter.category === 'completed') {
          res.status = [3, 4];
        } else if (this.filter.category === 'graduated') {
          res.status = [6, 7];
        } else if (this.filter.category === 'cancelled') {
          res.status = [5];
        }
        res.category = this.filter.category;
      }
      this.$store.commit('setFilterParams', {componentName: this.$options.name, params: this.filter});
      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
      }
      return res;
    },
    /**
       * Запись параметров из URL в this.filter
       * */
    initRouteParams() {
      const params = URLToQuery(this.$route.fullPath);
      const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (Object.keys(params).length !== 0) {
        if (params.hasOwnProperty('route_ways')) {
          params.route_ways = arrayStringToNumber(params.route_ways);
        }
        if (params.hasOwnProperty('stl_id')) {
          params.stl_id = arrayStringToNumber(params.stl_id);
        }
        if (params.hasOwnProperty('start_date_from')) {
          params.start_date_from = moment.utc(params.start_date_from).tz(clientTimezone).format('YYYY-MM-DDTHH:mm');
        }
        if (params.hasOwnProperty('start_date_to')) {
          params.start_date_to = moment.utc(params.start_date_to).tz(clientTimezone).format('YYYY-MM-DDTHH:mm');
        }
        if (params.hasOwnProperty('stop_date_from')) {
          params.stop_date_from = moment.utc(params.stop_date_from).tz(clientTimezone).format('YYYY-MM-DDTHH:mm');
        }
        if (params.hasOwnProperty('stop_date_to')) {
          params.stop_date_to = moment.utc(params.stop_date_to).tz(clientTimezone).format('YYYY-MM-DDTHH:mm');
        }
        if (params.hasOwnProperty('status')) {
          if ((params.status.indexOf('1') !== -1 ^ params.status.indexOf('2') !== -1) || (params.status.indexOf('3') !== -1 ^ params.status.indexOf('4') !== -1)) {
            params.status = arrayStringToNumber(params.status);
          } else {
            delete params.status;
          }
        }
        if (params.hasOwnProperty('category')) {
          if (params.category === 'drafts') {
            this.statuses = [
              {
                value: 0,
                text: 'Черновик',
              },
            ];
            this.columns = [
              'name',
              'userId',
              'routeWays',
              'routeCount',
              'transportationName',
              'transportationType',
              'stl',
              'summ',
              'dimensions',
              'actions',
            ];
          } else if (params.category === 'active') {
            this.statuses = [
              {
                value: 1,
                text: 'Ожидает начала',
              },
              {
                value: 2,
                text: 'Прием предложений',
              },
            ];
            this.columns = [
              'name',
              'userId',
              'routeWays',
              'routeCount',
              'transportationName',
              'transportationType',
              'stl',
              'summ',
              'activeBid',
              'tenderContractorProposals',
              'startDate',
              'stopDate',
              'status',
              'dimensions',
              'actions',
            ];
          } else if (params.category === 'completed') {
            this.statuses = [
              {
                value: 3,
                text: 'Нет победителя',
              },
              {
                value: 4,
                text: 'Есть победитель',
              },
            ];
            this.columns = [
              'name',
              'userId',
              'routeWays',
              'routeCount',
              'transportationName',
              'transportationType',
              'stl',
              'summ',
              'winnerProposal',
              'tenderContractorProposals',
              'startDate',
              'stopDate',
              'status',
              'dimensions',
              'actions',
            ];
          } else if (params.category === 'graduated') {
            this.statuses = [
              {
                value: 6,
                text: 'Утвержден',
              },
              {
                value: 7,
                text: 'Ожидание генерации рейсов',
              },
            ];
            this.columns = [
              'name',
              'userId',
              'routeWays',
              'routeCount',
              'transportationName',
              'transportationType',
              'stl',
              'summ',
              'winnerProposal',
              'tenderContractorProposals',
              'startDate',
              'stopDate',
              'status',
              'dimensions',
              'actions',
            ];
          } else if (params.category === 'cancelled') {
            this.statuses = [
              {
                value: 5,
                text: 'Отменен',
              },
            ];
            this.columns = [
              'name',
              'userId',
              'routeWays',
              'routeCount',
              'transportationName',
              'transportationType',
              'summ',
              'dimensions',
              'actions',
            ];
          }
        }
        if (params.hasOwnProperty('contractor')) {
          params.contractor = arrayStringToNumber(params.contractor);
        }
        if (params.hasOwnProperty('page')) {
          params.page = Number(params.page);
        }
        this.$nextTick(()=> {
          Object.assign(this.filter, params);
          this.filter.ready = true;
        });
      } else {
        const params = this.$store.getters.getFilterParams(this.$options.name);
        if (params.hasOwnProperty('route_ways')) {
          params.route_ways = arrayStringToNumber(params.route_ways);
        }
        if (params.hasOwnProperty('stl_id')) {
          params.stl_id = arrayStringToNumber(params.stl_id);
        }
        if (params.hasOwnProperty('status')) {
          if ((params.status.indexOf('1') !== -1 ^ params.status.indexOf('2') !== -1) || (params.status.indexOf('3') !== -1 ^ params.status.indexOf('4') !== -1)) {
            params.status = arrayStringToNumber(params.status);
          } else {
            delete params.status;
          }
        }
        if (params.hasOwnProperty('category')) {
          if (params.category === 'drafts') {
            this.statuses = [
              {
                value: 0,
                text: 'Черновик',
              },
            ];
            this.columns = [
              'name',
              'userId',
              'routeWays',
              'routeCount',
              'transportationName',
              'transportationType',
              'stl',
              'summ',
              'dimensions',
              'actions',
            ];
          } else if (params.category === 'active') {
            this.statuses = [
              {
                value: 1,
                text: 'Ожидает начала',
              },
              {
                value: 2,
                text: 'Прием предложений',
              },
            ];
            this.columns = [
              'name',
              'userId',
              'routeWays',
              'routeCount',
              'transportationName',
              'transportationType',
              'stl',
              'summ',
              'activeBid',
              'tenderContractorProposals',
              'startDate',
              'stopDate',
              'status',
              'dimensions',
              'actions',
            ];
          } else if (params.category === 'completed') {
            this.statuses = [
              {
                value: 3,
                text: 'Нет победителя',
              },
              {
                value: 4,
                text: 'Есть победитель',
              },
            ];
            this.columns = [
              'name',
              'userId',
              'routeWays',
              'routeCount',
              'transportationName',
              'transportationType',
              'stl',
              'summ',
              'winnerProposal',
              'tenderContractorProposals',
              'startDate',
              'stopDate',
              'status',
              'dimensions',
              'actions',
            ];
          } else if (params.category === 'graduated') {
            this.statuses = [
              {
                value: 6,
                text: 'Утвержден',
              },
              {
                value: 7,
                text: 'Ожидание генерации рейсов',
              },
            ];
            this.columns = [
              'name',
              'userId',
              'routeWays',
              'routeCount',
              'transportationName',
              'transportationType',
              'stl',
              'summ',
              'winnerProposal',
              'tenderContractorProposals',
              'startDate',
              'stopDate',
              'status',
              'dimensions',
              'actions',
            ];
          } else if (params.category === 'cancelled') {
            this.statuses = [
              {
                value: 5,
                text: 'Отменен',
              },
            ];
            this.columns = [
              'name',
              'userId',
              'routeWays',
              'routeCount',
              'transportationName',
              'transportationType',
              'summ',
              'dimensions',
              'actions',
            ];
          }
        }
        if (params.hasOwnProperty('contractor')) {
          params.contractor = arrayStringToNumber(params.contractor);
        }
        if (params.hasOwnProperty('page')) {
          params.page = Number(params.page);
        }
        if (params) {
          this.$nextTick(()=> {
            Object.assign(this.filter, params);
            this.filter.ready = true;
          });
        } else {
          this.filter.ready = true;
        }
      }
    },
      async customersRoutesManagersList(query) {
      this.loading = true;
      const params = {limit: 100};
      if (query) {
        params.query = query;
      }
      const response = await routeOwnersList( params);
      if (response && response.status === 200) {
        this.managers = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      }
      if (this.filter.stl_id.length > 0) {
        await this.customersRoutesSelectedManagersList();
      }
      this.loading = false;
    },
    async customersRoutesSelectedManagersList() {
      this.loading = true;
      const selectedManagers = {};
      this.filter.stl_id.map((item, index) => {
        selectedManagers['id[' + index + ']'] = item;
      });
      const response = await routeOwnersList(selectedManagers);
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.managers = this.managers.filter((x) => x.value !== item.id);
          this.managers.unshift({
            value: item.id,
            text: item.name,
          });
        });
      }
      this.loading = false;
    },
    async onStart() {
      this.loading = true;
      if (this.tendersObject.status === 0) {
        const response = await customersTenderClearRun(this.$store.state.user.ownerId, this.tendersObject.id, this.getFormData(this.formStrStart));
        if (response && response.status === 200) {
          this.showSuccess('Тендер подготовлен к запуску');
          this.$refs.table.refresh();
        }
      } else if (this.tendersObject.status === 1) {
        const response = await customersTenderChangeDates(this.$store.state.user.ownerId, this.tendersObject.id, this.formStrStart);
        if (response && response.status === 200) {
          this.showSuccess('Даты запуска тендера успешно обновлены');
          this.$refs.table.refresh();
        }
      } else if (this.tendersObject.status === 3) {
        const response = await customersTenderDraft(this.$store.state.user.ownerId, this.tendersObject.id);
        if (response && response.status === 200) {
          this.loading = true;
          const response = await customersTenderClearRun(this.$store.state.user.ownerId, this.tendersObject.id, this.getFormData(this.formStrStart));
          if (response && response.status === 200) {
            this.showSuccess('Тендер подготовлен к запуску');
            this.$refs.table.refresh();
          }
          this.loading = false;
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">

  .tender-filter-tabs {
    margin: -1px;
    &__input {
      display: none;

      &:checked {

        ~ .tender-filter-tabs__display {
          color: #23282c;
          background-color: #fff;
          border-color: #c8ced3 #c8ced3 #fff;
        }
      }
    }
    &__display {
      background-color: transparent;
      margin-bottom: 0;
    }
  }
</style>
